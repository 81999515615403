import {
    FONT_SIZE_PHONE,
    MAX_WIDTH_PHONE_LANDSCAPE,
    MAX_WIDTH_PHONE_PORTRAIT,
} from 'src/constants/config';
import { NorwayDayjs, norwaydayjs } from 'src/utils/dates';
import React, { useEffect, useState } from 'react';

import { WHITE } from 'src/constants/colors';
import styled from 'styled-components';

/**
 * @description clock syle CSS
 */
const ClockStyled = styled.div`
    align-items: center;
    color: ${WHITE};
    display: flex;
    font-weight: 500;
    justify-content: flex-start;

    @media (max-width: ${MAX_WIDTH_PHONE_PORTRAIT}px) {
        font-size: ${FONT_SIZE_PHONE}rem;
    }

    @media (max-width: ${MAX_WIDTH_PHONE_LANDSCAPE}px) and (min-width: ${MAX_WIDTH_PHONE_PORTRAIT +
        1}px) {
        font-size: ${FONT_SIZE_PHONE}rem;
        min-width: 15rem;
        padding-left: 1rem;
    }

    @media (min-width: ${MAX_WIDTH_PHONE_LANDSCAPE + 1}px) {
        min-width: 20rem;
        padding-left: 2rem;
    }
`;
/**
 * @description time text style CSS
 */
const TimeText = styled.span``;
/**
 * @description Clock functional component displayed in the header
 * @type {React.FC}
 */
const Clock: React.FC = () => {
    // Datetime state
    const [datetime, setDatetime] = useState<NorwayDayjs>(norwaydayjs());
    /**
     * @function updateDatetime
     * @description update the datetime state
     * @returns {void}
     */
    const updateDatetime = (): void => {
        setDatetime(norwaydayjs());
    };
    // useEffect for starting the interval and clearing when the component is unmounted
    useEffect(() => {
        const interval = setInterval(updateDatetime, 1000);
        return () => clearInterval(interval);
    }, []);

    return (
        <ClockStyled>
            <TimeText>
                {' '}
                Time in Norway : {datetime.format('DD/MM/YYYY HH:mm:ss')}
            </TimeText>
        </ClockStyled>
    );
};

export default Clock;
