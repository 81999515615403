import { Measurement, MeasurementStatistic, Provider } from '@nne-viz/common';

const translateProvider = (provider: Provider): string => {
    switch (provider) {
        case Provider.Telia:
            return 'Telia';
        case Provider.Telenor:
            return 'Telenor';
        case Provider.LAN:
            return 'LAN';
        case Provider.ICENET:
            return 'ICE.net';
        case Provider.ICENETR:
            return 'ICE.net R';
        case Provider.OneCall:
            return 'One Call';
        case Provider.NetworkNorway:
            return 'Network Norway';
    }
};

/**
 * @function translateStatus
 * @description Translate a status to human readable
 * @param {string} status the status
 * @returns {string} Translated status
 */
const translateStatus = (status: string): string => {
    switch (status) {
        case 'ALL':
            return 'All networks available';
        case 'SOME':
            return 'Some networks available';
        case 'UNK':
            return 'Node unreachable';
        case 'NONE':
            return 'No networks available';
        default:
            return status;
    }
};

/**
 * @function translateMeasurement
 * @description Translate a graphic dipslay enum to a human readable value
 * @param {Measurement} value Graphic display value
 * @returns {string} Translated value
 */
const translateMeasurement = (value: Measurement): string => {
    switch (value) {
        case Measurement.PACKET_LOSS:
            return 'Packet loss';
        case Measurement.LATENCY:
            return 'Latency';
        case Measurement.UPLOAD_SPEED:
            return 'Upload speed';
        case Measurement.DOWNLOAD_SPEED:
            return 'Download speed';
        default:
            return value;
    }
};

const translateMeasurementStatistic = (value: MeasurementStatistic): string => {
    switch (value) {
        case MeasurementStatistic.NETWORK_DAILY_MEDIAN:
            return 'Daily median per operator';
        case MeasurementStatistic.NODE_AND_NETWORK_DAILY:
            return 'Daily per node and operator';
        case MeasurementStatistic.NODE_AND_NETWORK_DAILY_MEDIAN:
            return 'Daily median per node and operator';
        case MeasurementStatistic.NETWORK_DAILY:
            return 'Daily per operator';
        default:
            return value;
    }
};

export {
    translateProvider,
    translateStatus,
    translateMeasurement,
    translateMeasurementStatistic,
};
