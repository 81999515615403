import { BLACK, BORDER, SUBTHEME, WHITE } from 'src/constants/colors';
import FromToTimeSlider, {
    STEP,
} from 'src/components/datetime/FromToTimeSlider';
import { IInterface, Measurement, Network, Nullable } from '@nne-viz/common';
import {
    MAX_WIDTH_PHONE_LANDSCAPE,
    MAX_WIDTH_PHONE_PORTRAIT,
} from 'src/constants/config';
import { Method, useApi } from 'src/services/api';
import { NorwayDayjs, norwaydayjs } from 'src/utils/dates';
import React, { useEffect, useState } from 'react';

import Base from 'src/components/Base';
import DatePicker from 'src/components/datetime/DatePicker';
import IInterfaceGraph from 'src/components/iinterface/IInterfaceGraph';
import IInterfaceSearchList from 'src/components/iinterface/IInterfaceSearchList';
import MeasurementSelect from 'src/components/select/MeasurementSelect';
import NetworksSelect from 'src/components/select/NetworksSelect';
import Scene from 'src/constants/scene';
import SignInGoogleButton from 'src/components/buttons/SignInGoogleButton';
import { measureUnit } from 'src/utils/math';
import styled from 'styled-components';
import { translateMeasurement } from 'src/utils/translate';
import { useAuthorization } from 'src/services/auth';
import { useQuery } from 'src/services/url';

/**
 * @description main container
 */
const Container = styled.div`
    display: flex;
    flex: 1;
    overflow: auto;

    @media (max-width: ${MAX_WIDTH_PHONE_PORTRAIT}px) {
        display: flex;
        flex-direction: column;
        overflow: hidden;
    }

    @media (max-width: ${MAX_WIDTH_PHONE_LANDSCAPE}px) and (min-width: ${MAX_WIDTH_PHONE_PORTRAIT +
        1}px) {
        overflow: hidden;
    }
`;

/**
 * @description caption container
 */
const CaptionContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: auto;
`;

/**
 * @description graphic container
 */
const GraphContainer = styled.div`
    display: flex;
    flex: 3;
    justify-content: center;

    @media (max-width: ${MAX_WIDTH_PHONE_PORTRAIT}px) {
        min-height: 70vh;
        max-height: 70vh;
    }

    @media (max-width: ${MAX_WIDTH_PHONE_LANDSCAPE}px) and (min-width: ${MAX_WIDTH_PHONE_PORTRAIT +
        1}px) {
        flex: 2;
        min-height: 95%;
        max-height: 95%;
    }
`;

/**
 * @description from to date container
 */
const FromToDateContainer = styled.div`
    align-items: flex-start;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    margin: 1rem 0rem 0rem 0rem;
`;

/**
 * @description select container
 */
const SelectContainer = styled.div`
    flex: 1;
    margin: 1rem 0rem 0rem 0rem;
`;

const TimeSelectContainer = styled.div`
    flex: 1;
    margin: 1rem 0rem 0rem 0rem;
    overflow: auto;
`;

const IInterfaceSearchListContainer = styled.div`
    display: flex;
    flex: 1;
    overflow: auto;

    @media (max-width: ${MAX_WIDTH_PHONE_PORTRAIT}px) {
        max-height: 70vh;
    }

    @media (max-width: ${MAX_WIDTH_PHONE_LANDSCAPE}px) and (min-width: ${MAX_WIDTH_PHONE_PORTRAIT +
        1}px) {
        max-height: 100vh;
    }
`;

/**
 * @description header container
 */
const HeaderContainer = styled.div`
    background: ${SUBTHEME};
    border-radius: 7px;
    color: ${BLACK};
    display: flex;
    flex-direction: column;
    margin: 0 0 1rem 0;
    padding: 0rem 1rem 1rem 1rem;
`;

/**
 * @description sub container for the graph
 */
const SubGraphContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    text-align: center;
`;

/**
 * @description Contain that is not interactable
 */
const DisableContainer = styled(Container)`
    display: flex;
    pointer-events: none;
    opacity: 0.4;
`;

const EmptyDataContainer = styled.div`
    align-items: center;
    background: ${SUBTHEME};
    border: solid;
    border-color: ${BORDER};
    border-radius: 5px;
    border-width: 1px;
    display: flex;
    flex: 1;
    margin: 1rem;
    justify-content: center;
`;

const EmptySpan = styled.span`
    background: ${WHITE};
    border: solid;
    border-color: ${BORDER};
    border-radius: 5px;
    border-width: 1px;
    padding: 1rem 1rem;
`;

/**
 * @description graph title span CSS
 */
const GraphTitle = styled.span`
    font-size: 1rem;
    font-weight: bold;
`;

const SignInButtonContainer = styled.div`
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: center;
`;

interface URLParams {
    measurement: Nullable<Measurement>,
    networks: Nullable<number[]>,
    nodes: Nullable<number[]>,
    datetime: Nullable<NorwayDayjs>,
}

/**
 * @type {React.FC}
 * @description the map dashboard
 */
const Dashboard: React.FC = () => {
    // retrieve URL params
    const query = useQuery();
    const params: URLParams = {
        measurement: query.getEnumString(Measurement, 'measurement'),
        networks: query.getArrayNumber('networks'),
        nodes: query.getArrayNumber('nodes'),
        datetime: query.getDatetime('datetime'),
    };
    // retrieve is user is authorized
    const [isAuthorized] = useAuthorization();
    const [haveParamsBeenUsed, setHaveParamsBeenUsed] = useState<boolean>(false);
    // network state to display data from
    const [networks, setNetworks] = useState<Network[]>([]);
    // measurement state to display data
    const [measurement, setMeasurement] = useState<Measurement>(
        params.measurement ? params.measurement : Measurement.LATENCY
    );
    // displayed interfaces state in the list and the graph
    const [displayedIInterfaces, setDisplayedIInterfaces] = useState<
        IInterface[]
    >([]);
    // selected interfaces in the list
    const [selectedIInterfaces, setSelectedIInterfaces] = useState<
        IInterface[]
    >([]);
    // define comparative hour gape according to window size
    const COMPARATIVE_HOUR_GAP: number =
        window.innerWidth > MAX_WIDTH_PHONE_LANDSCAPE ? 3 : 1;
    // init to state
    const initTo = params.datetime ? params.datetime : norwaydayjs();
    // selected dates
    const [[from, to], setFromTo] = useState<[NorwayDayjs, NorwayDayjs]>([
        initTo.subtract(COMPARATIVE_HOUR_GAP, 'hours'),
        initTo,
    ]);
    // delayed selected dates
    const [[delayedFrom, delayedto], setDelayedFromTo] = useState<
        [NorwayDayjs, NorwayDayjs]
    >([from, to]);
    const [{ data: iinterfaces, isLoading }, doFetchIInterfaces] = useApi<
        IInterface[]
    >({
        method: Method.POST,
        isAuthorizationNeeded: true,
        initialData: [],
        initialEndpoint: 'interface',
        initialBody: {
            networks_id: networks.map((network) => network.id),
        },
    });

    useEffect(() => {
        setSelectedIInterfaces((previousSelectedIInterfaces: IInterface[]) => {
            const networkIds = networks.map(network => network.id);
            return previousSelectedIInterfaces.filter(previousSelectedIInterface => networkIds.includes(previousSelectedIInterface.networkId));
        });
        doFetchIInterfaces({
            endpoint: 'interface',
            body: {
                networks_id: networks.map((network) => network.id),
            },
        });
    }, [networks]);

    useEffect(() => {
        setDisplayedIInterfaces(iinterfaces);
        if(params.nodes && params.networks && !haveParamsBeenUsed && iinterfaces.length > 0 ){
            setHaveParamsBeenUsed(true);
            setSelectedIInterfaces(
                iinterfaces.filter(
                    iinterface => params.nodes && params.networks ? params.nodes.includes(iinterface.nodeId) && params.networks.includes(iinterface.networkId) : false
                )
            );
        }
    }, [iinterfaces]);

    useEffect(() => {
        const timeOut = setTimeout(() => setDelayedFromTo([from, to]), 500);
        return () => clearTimeout(timeOut);
    }, [from, to]);

    return (
        <Base scene={Scene.COMPARATIVE}>
            {isAuthorized ? (
                <Container>
                    <CaptionContainer>
                        <HeaderContainer>
                            <SelectContainer>
                                <NetworksSelect
                                    initialNetworks={params.networks ? params.networks : 'all'}
                                    text={'Select operators'}
                                    networks={networks}
                                    setNetworks={(networks: Network[]) => {
                                        setNetworks(networks);
                                    }}
                                />
                            </SelectContainer>
                            <SelectContainer>
                                <MeasurementSelect
                                    text={'Select a measurement'}
                                    measurement={measurement}
                                    measurementType="interface"
                                    setMeasurement={setMeasurement}
                                />
                            </SelectContainer>
                            <FromToDateContainer>
                                <DatePicker
                                    value={from}
                                    setValue={(date: NorwayDayjs) => {
                                        const now: NorwayDayjs = norwaydayjs();
                                        if (
                                            date.isSame(now, 'day') &&
                                            to.hour() * 60 + to.minute() >
                                                now.hour() * 60 + now.minute()
                                        ) {
                                            setFromTo([
                                                norwaydayjs().subtract(
                                                    COMPARATIVE_HOUR_GAP,
                                                    'hours'
                                                ),
                                                norwaydayjs(),
                                            ]);
                                        } else {
                                            const aux = date
                                                .hour(to.hour())
                                                .minute(to.minute());
                                            setFromTo([
                                                aux.subtract(
                                                    COMPARATIVE_HOUR_GAP,
                                                    'hours'
                                                ),
                                                aux,
                                            ]);
                                        }
                                    }}
                                />
                            </FromToDateContainer>
                            <TimeSelectContainer>
                                <FromToTimeSlider
                                    fromHour={from.hour()}
                                    fromMinute={from.minute()}
                                    gap={COMPARATIVE_HOUR_GAP}
                                    onChange={(
                                        fromHour: number,
                                        fromMinute: number
                                    ) => {
                                        const [currentHour, currentMinute]: [
                                            number,
                                            number
                                        ] = [
                                            norwaydayjs().hour(),
                                            norwaydayjs().minute(),
                                        ];
                                        if (
                                            !to.isSame(norwaydayjs(), 'day') ||
                                            (fromHour + COMPARATIVE_HOUR_GAP) *
                                                60 +
                                                fromMinute <
                                                currentHour * 60 +
                                                    (currentMinute - STEP)
                                        )
                                            setFromTo([
                                                from
                                                    .hour(fromHour)
                                                    .minute(fromMinute),
                                                to
                                                    .hour(
                                                        fromHour +
                                                            COMPARATIVE_HOUR_GAP
                                                    )
                                                    .minute(fromMinute),
                                            ]);
                                    }}
                                />
                            </TimeSelectContainer>
                        </HeaderContainer>

                        {isLoading ? (
                            <DisableContainer>
                                <IInterfaceSearchListContainer>
                                    <IInterfaceSearchList
                                        iinterfaces={iinterfaces}
                                        isSelectable={true}
                                        displayedIInterfaces={
                                            displayedIInterfaces
                                        }
                                        setDisplayedIInterfaces={
                                            setDisplayedIInterfaces
                                        }
                                        selectedIInterfaces={
                                            selectedIInterfaces
                                        }
                                        setSelectedIInterfaces={
                                            setSelectedIInterfaces
                                        }
                                    />
                                </IInterfaceSearchListContainer>
                            </DisableContainer>
                        ) : (
                            <IInterfaceSearchListContainer>
                                <IInterfaceSearchList
                                    iinterfaces={iinterfaces}
                                    isSelectable={true}
                                    displayedIInterfaces={displayedIInterfaces}
                                    setDisplayedIInterfaces={
                                        setDisplayedIInterfaces
                                    }
                                    selectedIInterfaces={selectedIInterfaces}
                                    setSelectedIInterfaces={setSelectedIInterfaces}
                                />
                            </IInterfaceSearchListContainer>
                        )}
                    </CaptionContainer>
                    <GraphContainer>
                        <SubGraphContainer>
                            <GraphTitle>
                                Aggregate {translateMeasurement(measurement)} in{' '}
                                {measureUnit(measurement)} from{' '}
                                {delayedFrom.format('HH:mm')} to{' '}
                                {delayedto.format('HH:mm')} on{' '}
                                {delayedFrom.format('DD/MM/YYYY')}
                            </GraphTitle>
                            {selectedIInterfaces.length > 0 ? (
                                <IInterfaceGraph
                                    from={delayedFrom}
                                    to={delayedto}
                                    iinterfaces={selectedIInterfaces}
                                    measurement={measurement}
                                />
                            ) : (
                                <EmptyDataContainer>
                                    <EmptySpan>
                                        No interfaces selected.
                                    </EmptySpan>
                                </EmptyDataContainer>
                            )}
                        </SubGraphContainer>
                    </GraphContainer>
                </Container>
            ) : (
                <SignInButtonContainer>
                    <SignInGoogleButton />
                </SignInButtonContainer>
            )}
        </Base>
    );
};

export default Dashboard;
