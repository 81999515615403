import { LIGHT_GRAY, MEDIUM_GRAY, WHITE } from 'src/constants/colors';

import React from 'react';
import styled from 'styled-components';

const Button = styled.button`
    background: ${WHITE};
    border: solid;
    border-color: ${LIGHT_GRAY};
    border-radius: 5px;
    border-width: 1px;
    margin: 0rem 1rem 0rem 1rem;

    &:hover {
        border-color: ${MEDIUM_GRAY};
        background: ${MEDIUM_GRAY};
    }
`;

const SimulaMet: React.FC = () => {
    return (
        <Button onClick={() => window.open('https://www.simulamet.no/')}>
            <svg
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="100px"
                height="25px"
                viewBox="0 0 1037.895 174.25"
                enableBackground="new 0 0 1037.895 174.25"
            >
                <g>
                    <path
                        fill="#F15A22"
                        d="M115.103,20.937c0,10.226,8.343,18.84,18.481,18.84c10.371,0,18.472-8.614,18.472-18.84
		c0-10.691-8.101-19.296-18.472-19.296C123.445,1.641,115.103,10.245,115.103,20.937 M512.256,170.222h-27.337V7.199h27.337V170.222
		z M147.252,56.075h-27.358v114.127h27.358V56.075z M363.866,56.095h27.338v60.672c0,8.13,0.233,16.046,2.871,22.042
		c2.639,5.976,7.916,10.068,17.5,10.068c17.017,0,24.448-13.192,24.448-31.645V56.095h27.339v114.127h-27.339v-14.649
		c-5.509,9.605-17.752,17.521-34.032,17.521c-11.506,0-21.343-3.608-28.057-11.041c-7.197-8.147-10.068-16.045-10.068-41.23V56.095z
		 M259.545,64.243c-6.713-7.431-16.55-11.021-28.289-11.021c-17.986,0-30.191,7.896-35.72,17.502v-14.63h-27.319v114.127h27.319
		v-61.139c0-18.472,7.916-31.646,24.699-31.646c20.625,0,22.061,14.377,22.061,35.002v57.782h27.338v-61.139
		c0-18.472,7.665-31.646,24.681-31.646c20.605,0,22.061,14.377,22.061,35.002v57.782h27.319v-64.729
		c0-25.165-2.853-33.101-10.051-41.25c-5.996-6.713-15.6-11.021-30.229-11.021c-20.606,0-32.112,9.352-39.077,17.733
		C262.902,68.552,261.485,66.416,259.545,64.243 M84.446,88.458C76.54,80.794,65.51,75.517,52.315,75.517
		c-10.788,0-18.694,3.822-18.694,11.254c0,8.168,7.906,9.837,23.981,13.193l13.65,3.124c17.269,4.074,32.868,10.555,32.868,31.412
		c0,26.136-23.749,38.593-49.874,38.593c-27.339,0-45.8-13.427-52.746-23.244l18.451-16.318
		c6.481,8.636,17.502,17.017,35.013,17.017c13.669,0,21.575-4.773,21.575-12.924c0-7.935-6.48-9.37-17.501-11.757l-17.035-3.608
		C22.116,117.95,6.544,109.568,6.544,88.71c0-24.933,24.195-35.487,46.014-35.487c22.788,0,40.27,10.069,47.721,18.22L84.446,88.458
		z M628.07,144.26V87.662c0-25.165-19.209-33.547-45.324-33.547c-27.339,0-50.35,11.253-49.865,39.311l25.65,1.668
		c-0.253-13.427,7.665-19.888,23.012-19.888c14.163,0,20.373,4.792,20.373,14.396v1.902c0,4.307-1.183,4.792-7.198,5.994
		l-25.166,4.327c-14.861,2.64-26.834,7.18-33.8,15.562c-4.073,5.045-6.479,11.524-6.479,20.159
		c0,24.933,18.219,35.953,40.278,35.953c19.655,0,27.396-9.701,32.403-14.648v11.176h26.098v-25.631
		C628.053,144.357,628.07,144.3,628.07,144.26 M601.916,129.863c0,15.58-10.284,23.012-25.166,23.012
		c-13.427,0-19.653-6.229-19.653-15.581c0-9.118,5.762-12.476,18.704-15.095l10.533-2.154c8.15-1.668,11.507-2.657,15.582-4.073
		V129.863z"
                    />
                    <path
                        fill="#241363"
                        d="M1036.525,149.381c-2.884,0.48-7.209,0.961-12.016,0.961c-4.806,0-9.371-0.721-11.534-3.124
		c-2.163-2.162-3.124-5.286-3.124-11.774V77.052h26.674V55.666h-26.674V9.77l-27.393,6.007v39.89h-24.51v21.386h24.51v62.958
		c0,11.053,0.24,17.541,4.805,23.067c6.248,7.689,17.782,9.853,28.836,9.853c8.17,0,15.139-0.721,20.426-1.922V149.381z
		 M899.316,74.89c15.379,0,26.191,10.092,26.912,24.99h-55.749C871.201,86.183,883.697,74.89,899.316,74.89 M934.879,129.677
		c-3.604,5.767-12.735,19.704-33.401,19.704c-20.184,0-29.796-13.456-31.719-29.076h83.383c0-1.922,0.24-5.046,0.24-6.968
		c0-35.083-17.541-60.314-54.065-60.314c-31.961,0-56.229,23.549-56.229,58.872c0,37.005,23.068,61.035,56.95,61.035
		c33.4,0,48.779-20.906,53.826-28.835L934.879,129.677z M740.962,63.836c-6.728-7.449-16.58-11.055-28.355-11.055
		c-18.021,0-30.276,7.931-35.804,17.542V55.666H649.41v114.38h27.393v-61.275c0-18.503,7.93-31.719,24.751-31.719
		c20.665,0,22.107,14.418,22.107,35.083v57.911h27.393v-61.275c0-18.503,7.69-31.719,24.751-31.719
		c20.665,0,22.107,14.418,22.107,35.083v57.911h27.394v-64.879c0-25.231-2.884-33.161-10.093-41.331
		c-6.007-6.729-15.619-11.055-30.277-11.055c-20.665,0-32.199,9.372-39.168,17.782C744.326,68.16,742.884,65.999,740.962,63.836"
                    />
                </g>
            </svg>
        </Button>
    );
};

export default SimulaMet;
