"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var MeasurementStatistic;
(function (MeasurementStatistic) {
    MeasurementStatistic["NETWORK_DAILY"] = "network_daily";
    MeasurementStatistic["NETWORK_DAILY_MEDIAN"] = "network_daily_median";
    MeasurementStatistic["NODE_AND_NETWORK_DAILY"] = "node&network_daily";
    MeasurementStatistic["NODE_AND_NETWORK_DAILY_MEDIAN"] = "node&network_daily_median";
})(MeasurementStatistic || (MeasurementStatistic = {}));
exports.default = MeasurementStatistic;
