import { Method, useApi } from 'src/services/api';
import React, { useEffect } from 'react';

import BarLoader from 'src/components/loader/BarLoader';
import NetworkIndicator from 'src/img/NetworkIndicator';
import { UpRate as UpRateColors } from 'src/constants/colors';
import { UpRate as UpRateModel } from '@nne-viz/common';
import styled from 'styled-components';

/**
 * @description span props
 * @interface SpanProps
 */
interface SpanProps {
    /**
     * @description color of the text in span
     * @type {string}
     */
    color: string;
}
/**
 * @description span style CSS
 */
const Span = styled.span<SpanProps>`
    color: ${(props) => (props.color ? props.color : 'white')};
    font-size: 1.7rem;
    font-weight: bold;
    margin: 0 0 0 0.5rem;
`;
/**
 * @description up rate container style
 */
const UpRateContainer = styled.div`
    align-items: center;
    align-self: center;
    display: flex;
    flex: 1;
    margin: 0.5rem 0rem 0rem 0rem;
`;
/**
 * @function upRateToColor
 * @description convert the up rate to a color
 * @param {number} upRate
 * @returns {UpRateColors}
 */
const upRateToColor = (upRate: number): UpRateColors => {
    if (upRate <= 100 && upRate >= 90) return UpRateColors['VERY_HIGH'];
    if (upRate >= 80) return UpRateColors['HIGH'];
    if (upRate >= 70) return UpRateColors['MEDIUM'];
    if (upRate >= 50) return UpRateColors['LOW'];
    if (upRate >= 0) return UpRateColors['VERY_LOW'];
    return UpRateColors['DEFAULT'];
};
/**
 * @description props for the up rate component
 * @interface Props
 */
interface Props {
    networkId: number | undefined;
}
/**
 * @description up rate functional component
 * @type {React.FC}
 */
const UpRate: React.FC<Props> = ({ networkId }: Props) => {
    const [{ data: upRate, isLoading }, doFetch] = useApi<UpRateModel>({
        method: Method.GET,
        isAuthorizationNeeded: false,
        initialData: { value: 0 },
        initialEndpoint: networkId
            ? `node/up_rate?network_id=${networkId}`
            : 'node/up_rate',
    });

    // useEffect for retrieving up rate when networkId changes
    useEffect(() => {
        doFetch({
            endpoint: networkId
                ? `node/up_rate?network_id=${networkId}`
                : 'node/up_rate',
        });
    }, [networkId]);

    const renderIndicator = () => {
        return isLoading ? (
            <BarLoader />
        ) : (
            <>
                <NetworkIndicator upRate={upRate.value} />
                <Span color={upRateToColor(upRate.value)}>{upRate.value}%</Span>
            </>
        );
    };

    return (
        <>
            <span>Up rate</span>
            <UpRateContainer>{renderIndicator()}</UpRateContainer>
        </>
    );
};

export default UpRate;
