import { BORDER, HOVER, Status, WHITE } from 'src/constants/colors';

import Circle from 'src/img/Circle';
import { IInterface } from '@nne-viz/common';
import React from 'react';
import styled from 'styled-components';

/**
 * @description table row props
 * @interface TableRowProps
 */
interface TableRowProps {
    /**
     * @description is last index of the table
     * @type {boolean}
     */
    isLastIndex: boolean;
    /**
     * @description the length of the column
     * @type {number}
     */
    columnLength: number;
    /**
     * @description is selected
     * @type {boolean | undefined}
     */
    isSelected: boolean | undefined;
}
/**
 * @description table row style CSS
 */
const TableRow = styled.tr<TableRowProps>`
    background: ${(props) => (props.isSelected ? HOVER : WHITE)};
    border: solid;
    border-color: ${BORDER};
    border-width: 0 0 ${(props) => (props.isLastIndex ? '0' : '1')}px 0;
    display: grid;
    grid-template-columns: repeat(
        ${(props) => (props.columnLength ? props.columnLength : '1')},
        1fr
    );

    &:hover {
        background: ${BORDER};
    }
`;
/**
 * @description table data cell props
 * @interface TableDataCellProps
 */
interface TableDataCellProps {
    /**
     * @description column of the cell
     * @type {string}
     */
    grid_column: string;
}
/**
 * @description table data cell style CSS
 */
const TableDataCell = styled.td<TableDataCellProps>`
    grid-row: 1 / 1;
    grid-column: ${(props) =>
        props.grid_column ? props.grid_column : '1 / 1'};
    overflow: auto;
    padding: 1rem;
`;
/**
 * @description props of the node item component
 * @interface Props
 */
interface Props {
    /**
     * @description the interface of the interface item
     * @type {IInterface}
     */
    iinterface: IInterface;
    /**
     * @description is last index of the table
     * @type {boolean}
     */
    isLastIndex: boolean;
    /**
     * @description is selectable item
     * @type {boolean}
     */
    isSelectable: boolean;
    /**
     * @description is item selected
     * @type {boolean}
     */
    isSelected?: boolean;
    /**
     * @function onSelect
     * @description behavior when selecting interface
     * @param {IInterface} iinterface
     */
    onSelect?: (iinterface: IInterface) => void;
}
/**
 * @description node item functional component to display in a list of node
 * @type {React.FC}
 */
const IInteraceItem: React.FC<Props> = ({
    iinterface,
    isLastIndex,
    isSelectable,
    isSelected,
    onSelect,
}: Props) => {
    const render = () => {
        return (
            <>
                <TableRow
                    isLastIndex={isLastIndex}
                    columnLength={4}
                    isSelected={undefined}
                >
                    <TableDataCell grid_column="1 / 2">
                        {iinterface.nodeId}
                    </TableDataCell>
                    <TableDataCell grid_column="2 / 3">
                        {iinterface.provider}
                    </TableDataCell>
                    <TableDataCell grid_column="3 / 4">
                        {iinterface.location}
                    </TableDataCell>
                    <TableDataCell grid_column="4 / 5">
                        <Circle
                            fill={
                                Status[iinterface.status as keyof typeof Status]
                            }
                            size={10}
                        />
                    </TableDataCell>
                </TableRow>
            </>
        );
    };

    const renderSelectable = (
        isSelected: boolean,
        onSelect: (iinterface: IInterface) => void
    ) => {
        return (
            <>
                <TableRow
                    isLastIndex={isLastIndex}
                    columnLength={5}
                    onClick={() => onSelect(iinterface)}
                    isSelected={isSelected}
                >
                    <TableDataCell grid_column="1 / 2">
                        <input
                            type="checkbox"
                            checked={isSelected}
                            onChange={() => onSelect(iinterface)}
                        />
                    </TableDataCell>
                    <TableDataCell grid_column="2 / 3">
                        {iinterface.nodeId}
                    </TableDataCell>
                    <TableDataCell grid_column="3 / 4">
                        {iinterface.provider}
                    </TableDataCell>
                    <TableDataCell grid_column="4 / 5">
                        {iinterface.location}
                    </TableDataCell>
                    <TableDataCell grid_column="5 / 6">
                        <Circle
                            fill={
                                Status[iinterface.status as keyof typeof Status]
                            }
                            size={10}
                        />
                    </TableDataCell>
                </TableRow>
            </>
        );
    };

    if (isSelectable && isSelected !== undefined && onSelect !== undefined)
        return renderSelectable(isSelected, onSelect);
    else return render();
};

export default IInteraceItem;
