import React, { useState } from 'react';
import { SUBTHEME, WHITE } from 'src/constants/colors';

import { IInterface } from '@nne-viz/common';
import IInterfaceList from 'src/components/iinterface/IInterfaceList';
import Search from 'src/components/Search';
import styled from 'styled-components';

/**
 * @description interface search list style CSS
 */
const IInterfaceSearchListStyled = styled.div`
    border-color: ${SUBTHEME};
    border-radius: 5px;
    border-style: solid;
    border-width: 0.1rem;
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: auto;
`;

/**
 * @description search container style CSS
 */
const SearchContainer = styled.div`
    background: ${WHITE};
    display: flex;
`;
/**
 * @description props for the node search list
 * @interface Props
 */
interface Props {
    /**
     * @description list of interfaces
     * @type {IInterface[]}
     */
    iinterfaces: IInterface[];
    displayedIInterfaces: IInterface[];
    setDisplayedIInterfaces: (iinterfaces: IInterface[]) => void;
    isSelectable: boolean;
    selectedIInterfaces?: IInterface[];
    setSelectedIInterfaces?: React.Dispatch<React.SetStateAction<IInterface[]>>;
}
/**
 * @description node search list functional component for searching node in a list
 * @type {React.FC}
 */
const IInterfaceSearchList: React.FC<Props> = ({
    iinterfaces,
    displayedIInterfaces,
    setDisplayedIInterfaces,
    isSelectable,
    selectedIInterfaces,
    setSelectedIInterfaces,
}: Props) => {
    // the value of the search
    const [search, setSearch] = useState<string>('');

    /**
     * @async
     * @function updateNodeList
     * @description update the interface list according to the search value
     * @returns {void}
     */
    const updateInterfaceList = (search: string): void => {
        // if last character is *
        if (search.endsWith('*'))
        // create new list by copying inital node list and filtering value starting with

            setDisplayedIInterfaces(
                [...iinterfaces].filter(
                    (value) =>
                        value.nodeId
                            .toString()
                            .toLowerCase()
                            .startsWith(
                                search
                                    .substring(0, search.length - 1)
                                    .toLowerCase()
                            ) ||
                        value.location
                            .toLowerCase()
                            .startsWith(
                                search
                                    .substring(0, search.length - 1)
                                    .toLowerCase()
                            )
                )
            );
        // create new list by copying inital node list and filtering value including
        else
            setDisplayedIInterfaces(
                [...iinterfaces].filter(
                    (value) =>
                        value.nodeId
                            .toString()
                            .toLowerCase()
                            .includes(search.toLowerCase()) ||
                        value.location
                            .toLowerCase()
                            .includes(search.toLowerCase())
                )
            );
    };

    const render = () => {

        return (
            <IInterfaceSearchListStyled>
                <SearchContainer>
                    <Search
                        placeholder="Search by location or node id"
                        value={search}
                        onChange={(value: string) => {
                            setSearch(value);
                            updateInterfaceList(value);
                        }}
                    />
                </SearchContainer>
                <IInterfaceList
                    iinterfaces={displayedIInterfaces}
                    setIInterfaces={setDisplayedIInterfaces}
                    isSelectable={isSelectable}
                    selectedIInterfaces={selectedIInterfaces}
                    setSelectedIInterfaces={setSelectedIInterfaces}
                />
            </IInterfaceSearchListStyled>
        );
    };

    return render();
};

export default IInterfaceSearchList;
