import { Method, useApi } from 'src/services/api';
import React, { useEffect } from 'react';

import { Network } from '@nne-viz/common';
import Option from 'src/components/select/Option';
import Select from 'src/components/select/Select';
import styled from 'styled-components';
import { translateProvider } from 'src/utils/translate';

/**
 * @description select container style CSS
 */
const SelectContainer = styled.div`
    margin: 1rem 0rem 0rem 0rem;
`;
/**
 * @description props of the filter
 * @interface Props
 */
interface Props {
    initialNetworks: 'all' | 'none' | number[];
    /**
     * @description network
     * @type {Network[] \ undefined}
     */
    networks: Network[];
    /**
     * @function setNetworks
     * @param {Network[]} network
     * @returns {void}
     */
    setNetworks: (networks: Network[]) => void;
    /**
     * @description text
     * @type {string}
     */
    text: string;
}
/**
 * @description filter functional component
 * @type {React.FC}
 */
const NetworkSelect: React.FC<Props> = ({
    initialNetworks,
    text,
    networks,
    setNetworks,
}) => {
    const [{ data: _networks, isLoading }] = useApi<Network[]>({
        method: Method.GET,
        isAuthorizationNeeded: false,
        initialData: [],
        initialEndpoint: 'network',
    });

    useEffect(() => {
        if (initialNetworks === 'all') setNetworks(_networks);
        if (Array.isArray(initialNetworks)) setNetworks(_networks.filter(_network => initialNetworks.includes(_network.id)));
    }, [_networks]);

    return (
        <>
            <span>{text}</span>
            <SelectContainer>
                <Select
                    options={_networks.map((network) => ({
                        value: network.id,
                        label: translateProvider(network.provider),
                    }))}
                    isDisabled={isLoading}
                    value={networks.map((network) => ({
                        value: network.id,
                        label: translateProvider(network.provider),
                    }))}
                    isClearable={false}
                    isMulti={true}
                    onChange={(newNetworks) => {
                        const ids: number[] = (newNetworks as Option[]).map(
                            (option) => option.value as number
                        );

                        const aux: Network[] = _networks.filter((network) =>
                            ids.includes(network.id)
                        );
                        setNetworks(aux);
                    }}
                />
            </SelectContainer>
        </>
    );
};

export default NetworkSelect;
