import React from 'react';
import network_indicator from 'src/img/network_indicator.png';
import styled from 'styled-components';

/**
 * @description Props of the image
 * @interface ImgProps
 */
interface ImgProps {
    /**
     * @description position of the image
     * @type {string}
     */
    position: string;
}

/**
 * @descrition Image styled CSS
 */
const Img = styled.img<ImgProps>`
    height: 1.5rem;
    object-fit: cover;
    object-position: 100%
        ${(props) => (props.position ? props.position : '-7.5')}rem;
    width: 5rem;
`;

/**
 * @function upRateToPosition
 * @description according the the up rate position, returns the position of the image which change the color of the battery
 * @param {number} upRate
 * @returns {number}
 */
const upRateToPosition = (upRate: number): number => {
    if (upRate <= 100 && upRate >= 90) return 0;
    if (upRate >= 80) return -1.5;
    if (upRate >= 70) return -3;
    if (upRate >= 50) return -4.5;
    if (upRate >= 0) return -6;
    return -7.5;
};

/**
 * @description Network Indicator Props
 */
interface Props {
    upRate: number;
}

/**
 * @description Network Indicator
 * @param {Props} {upRate}
 * @type {React.FC}
 */
const NetworkIndicator: React.FC<Props> = ({ upRate }: Props) => {
    return (
        <Img
            src={network_indicator}
            alt="Network State Indicator"
            position={upRateToPosition(upRate).toString()}
        />
    );
};

export default NetworkIndicator;
