import CaptionItem from 'src/components/map/header/CaptionItem';
import React from 'react';
import { Status } from 'src/constants/colors';
import styled from 'styled-components';

/**
 * @description props for the container
 * @interface ContainerProps
 */
interface ContainerProps {
    /**
     * @description the CSS grid template column
     * @type {string}
     */
    gridTemplateColumns: string;
    /**
     * @description the CSS grid template row
     * @type {string}
     */
    gridTemplateRows: string;
}
/**
 * @description container style CSS
 */
const Container = styled.div<ContainerProps>`
    display: grid;
    flex: 1;
    grid-template-columns: ${(props) =>
        props.gridTemplateColumns ? props.gridTemplateColumns : 'auto'};
    grid-template-rows: ${(props) =>
        props.gridTemplateRows ? props.gridTemplateRows : 'auto'};
    margin: 0.5rem 0 0 0;
`;
/**
 * @description props for caption
 * @interface Props
 */
interface Props {
    /**
     * @description text for the caption
     * @type {string}
     */
    text: string;
}
/**
 * @description caption functional component
 * @type {React.FC}
 */
const Caption: React.FC<Props> = ({ text }: Props) => {
    // Column length
    const columnLength = 2;
    // Row length
    const rowLength = 2;
    // Key to exclude
    const keysToExclude = ['LTE', 'UNKNOWN', 'UNAVAILABLE'];
    /**
     * @function renderCaptionItems
     * @description render caption from keys
     * @param {string[]} keys
     * @returns {JSX.Element[]}
     */
    const renderCaptionItems = (keys: string[]): JSX.Element[] => {
        return keys.map((key: string, index: number) => {
            const row = Math.floor(index / columnLength);
            const column = index % columnLength;
            return (
                <CaptionItem
                    key={key}
                    status={key}
                    gridColumn={`${column + 1} / ${column + 2}`}
                    gridRow={`${row + 1} / ${row + 1}`}
                />
            );
        });
    };

    return (
        <>
            <span>{text}</span>
            <Container
                gridTemplateColumns={`repeat(${columnLength}, 1fr)`}
                gridTemplateRows={`repeat(${rowLength}, 1fr)`}
            >
                {renderCaptionItems(
                    Object.keys(Status).filter(
                        (key) => !keysToExclude.includes(key)
                    )
                )}
            </Container>
        </>
    );
};

export default Caption;
