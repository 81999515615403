import { BLACK, TEXT_HOVER } from 'src/constants/colors';
import React, { useState } from 'react';

import Sort from 'src/img/Sort';
import styled from 'styled-components';

/**
 * @description Conainer for SVG CSS
 */
const SVGContainer = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-end;
`;
/**
 * @description Props for Table Header Cell
 * @interface TableHeaderCellProps
 */
interface TableHeaderCellProps {
    /**
     * @description column of the cell refer to CSS
     * @type {string}
     */
    grid_column: string;
}

/**
 * @description Table header style CSS
 */
const TableHeaderCellStyled = styled.th<TableHeaderCellProps>`
    color: ${BLACK};
    display: flex;
    grid-row: 1 / 1;
    grid-column: ${(props) =>
        props.grid_column ? props.grid_column : '1 / 1'};
    overflow: auto;
    padding: 1rem;
    text-align: start;
    &:hover {
        color: ${TEXT_HOVER};
    }
`;

/**
 * @description Props for TableHeaderCell
 * @interface Props
 */
interface Props {
    /**
     * @description CSS for column
     * @type {string}
     */
    grid_column: string;
    /**
     * @function onClick
     * @description on click function
     * @return {void}
     */
    onClick: () => void;
    /**
     * @description text to display
     * @type {string}
     */
    text: string;
}

/**
 * @description Table Header Cell functional component
 * @type {React.FC}
 */
const TableHeaderCell: React.FC<Props> = ({
    grid_column,
    onClick,
    text,
}: Props) => {
    // isHover state of the table header cell, by default false
    const [isHover, setIsHover] = useState<boolean>(false);

    return (
        <TableHeaderCellStyled
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
            grid_column={grid_column}
            onClick={onClick}
        >
            <span>{text}</span>
            {isHover ? (
                <SVGContainer>
                    <Sort fill={TEXT_HOVER} />
                </SVGContainer>
            ) : null}
        </TableHeaderCellStyled>
    );
};

export default TableHeaderCell;
