import { BLACK, BORDER, HOVER } from 'src/constants/colors';

import React from 'react';
import styled from 'styled-components';

/**
 * @description Search styled CSS
 */
const SearchStyled = styled.input`
    background-color: #ebecee;
    border: solid;
    border-color: ${BORDER};
    border-radius: 5px;
    border-width: 2px;
    color: ${BLACK};
    font-size: 1rem;
    height: 3rem;
    margin: 0.5rem;
    display: flex;
    flex: 1;

    &:hover {
        border-color: ${HOVER};
        border-radius: 5px;
        border-width: 2px;
    }
`;
/**
 * @description Props for Search component
 * @interface Props
 */
interface Props {
    /**
     * @description place holder to display when text empty
     * @type {string | undefined}
     */
    placeholder?: string;
    /**
     * @description value of current search
     * @type {string}
     */
    value: string;
    /**
     * @function onChange
     * @description function to trigger when search value change
     * @param {string} change
     * @returns {void}
     */
    onChange: (change: string) => void;
}
/**
 * @description Search functional component
 * @type {React.FC}
 */
const Search: React.FC<Props> = ({ placeholder, value, onChange }: Props) => {
    return (
        <SearchStyled
            placeholder={placeholder}
            value={value}
            onChange={(event) => onChange(event.target.value)}
        />
    );
};

export default Search;
