import {
    FONT_SIZE_PHONE,
    MAX_WIDTH_PHONE_LANDSCAPE,
    MAX_WIDTH_PHONE_PORTRAIT,
} from 'src/constants/config';
import { MAIN_THEME, MEDIUM_GRAY, WHITE } from 'src/constants/colors';

import Clock from 'src/components/header/Clock';
import HeaderButton from 'src/components/buttons/HeaderButton';
import React from 'react';
import Scene from 'src/constants/scene';
import nornet from 'src/img/nornet.png';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

/**
 * @description header style css
 */
const HeaderStyled = styled.div`
    background: ${MAIN_THEME};
    color: white;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: 0rem 0rem 0rem 1rem;
`;
/**
 * @descrition Image styled CSS
 */
const Img = styled.img`
    background: ${WHITE};
    border: solid;
    border-color: ${WHITE};
    border-radius: 10px;
    border-width: 1px;
    margin: 0rem 1rem 0rem 0rem;
    object-fit: contain;
    padding: 0.5rem;

    &:hover {
        border-color: ${MEDIUM_GRAY};
        background: ${MEDIUM_GRAY};
    }

    @media (max-width: ${MAX_WIDTH_PHONE_PORTRAIT}px) {
        height: 0.5rem;
    }

    @media (max-width: ${MAX_WIDTH_PHONE_LANDSCAPE}px) and (min-width: ${MAX_WIDTH_PHONE_PORTRAIT +
        1}px) {
        height: 0.8rem;
    }

    @media (min-width: ${MAX_WIDTH_PHONE_LANDSCAPE + 1}px) {
        height: 2rem;
    }
`;
/**
 * @description end of header style CSS
 */
const HeaderEnd = styled.div`
    display: flex;
    grid-row: 1 / 1;
    justify-self: flex-end;

    @media (max-width: ${MAX_WIDTH_PHONE_PORTRAIT}px) {
        grid-column: 3 / 4;
    }

    @media (max-width: ${MAX_WIDTH_PHONE_LANDSCAPE}px) and (min-width: ${MAX_WIDTH_PHONE_PORTRAIT +
        1}px) {
        grid-column: 2 / 4;
    }

    @media (min-width: ${MAX_WIDTH_PHONE_LANDSCAPE + 1}px) {
        grid-column: 2 / 4;
    }
`;
const HeaderStart = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
`;
/**
 * @description header text style CSS
 */
const HeaderText = styled.h1`
    grid-row: 1 / 1;

    @media (max-width: ${MAX_WIDTH_PHONE_PORTRAIT}px) {
        font-size: ${FONT_SIZE_PHONE}rem;
        grid-column: 1 / 2;
    }

    @media (max-width: ${MAX_WIDTH_PHONE_LANDSCAPE}px) and (min-width: ${MAX_WIDTH_PHONE_PORTRAIT +
        1}px) {
        font-size: ${FONT_SIZE_PHONE}rem;
        grid-column: 1 / 2;
    }

    @media (min-width: ${MAX_WIDTH_PHONE_LANDSCAPE + 1}px) {
        grid-column: 1 / 2;
    }
`;
interface Props {
    /**
     * @description current scene
     * @type {Scene}
     */
    scene: Scene;
}
/**
 * @description header functional component for the HTML page
 * @type {React.FC}
 */
const Header: React.FC<Props> = ({ scene }) => {
    const navigate = useNavigate();
    return (
        <HeaderStyled>
            <HeaderStart>
                <Img
                    src={nornet}
                    alt="Nornet logo"
                    onClick={() => window.open('https://www.nntb.no/')}
                ></Img>
                <HeaderText>NorNet edge</HeaderText>
            </HeaderStart>

            <HeaderEnd>
                <HeaderButton
                    text={'Map'}
                    onClick={() => {
                        navigate('/map');
                    }}
                    isSelected={scene == Scene.MAP}
                />
                <HeaderButton
                    text={'Statistics'}
                    onClick={() => {
                        navigate('/statistics');
                    }}
                    isSelected={scene == Scene.STATISTICS}
                />

                <HeaderButton
                    text={'Comparative'}
                    onClick={() => {
                        navigate('/comparative');
                    }}
                    isSelected={scene == Scene.COMPARATIVE}
                />
                <Clock />
            </HeaderEnd>
        </HeaderStyled>
    );
};

export default Header;
