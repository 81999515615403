import 'src/components/datetime/FromToDatePicker.css';
import 'react-calendar/dist/Calendar.css';

import { NorwayDayjs, norwaydayjs } from 'src/utils/dates';

import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import React from 'react';
import styled from 'styled-components';

/**
 * @description container
 */
const Container = styled.div`
    margin: 1rem 0rem 0rem 0rem;
`;
/**
 * @description props fromtodate component
 * @type {Props}
 */
interface Props {
    /**
     * @description date value from and date value to
     * @type {[NorwayDayjs, NorwayDayjs]}
     */
    values: [NorwayDayjs, NorwayDayjs];
    /**
     * @function setValues
     * @description update values
     * @param {[NorwayDayjs, NorwayDayjs]} dates
     * @returns {void}
     */
    setValues: (dates: [NorwayDayjs, NorwayDayjs]) => void;
}
/**
 * @description from to date component
 * @type {React.FC}
 */
const FromToDatePicker: React.FC<Props> = ({ values, setValues }: Props) => {
    const _values: [Date, Date] = values.map((date) => date.toDate()) as [
        Date,
        Date
    ];
    return (
        <>
            <span>Select a date interval</span>
            <Container>
                <DateRangePicker
                    value={_values}
                    onChange={(values) => {
                        if (values) {
                            const aux: [Date, Date] = values as [Date, Date];
                            setValues(
                                aux.map((date) => norwaydayjs(date)) as [
                                    NorwayDayjs,
                                    NorwayDayjs
                                ]
                            );
                        }
                    }}
                    clearIcon={null}
                    format={'dd/MM/y'}
                    maxDate={norwaydayjs().toDate()}
                    minDate={norwaydayjs().subtract(1, 'month').toDate()}
                    required={true}
                />
            </Container>
        </>
    );
};

export default FromToDatePicker;
