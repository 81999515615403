import 'src/components/datetime/DatePicker.css';

import { NorwayDayjs, norwaydayjs } from 'src/utils/dates';

import React from 'react';
import ReactDatePicker from 'react-date-picker';
import styled from 'styled-components';

/**
 * @description container
 */
const Container = styled.div`
    margin: 1rem 0rem 0rem 0rem;
`;

/**
 * @description props SingleDate component
 * @type {Props}
 */
interface Props {
    value: NorwayDayjs;
    setValue: (value: NorwayDayjs) => void;
}

/**
 * @description SingleDate component
 * @type {React.FC}
 */
const DatePicker: React.FC<Props> = ({ value, setValue }: Props) => {
    const _value = value.toDate();
    return (
        <>
            <span>Select a date</span>
            <Container>
                <ReactDatePicker
                    clearIcon={null}
                    format="dd/MM/y"
                    maxDate={norwaydayjs().toDate()}
                    minDate={norwaydayjs().subtract(1, 'month').toDate()}
                    onChange={(value) => {
                        const aux = value as Date;
                        setValue(norwaydayjs(aux));
                    }}
                    value={_value}
                />
            </Container>
        </>
    );
};

export default DatePicker;
