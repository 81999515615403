import Footer from 'src/components/Footer';
import Header from 'src/components/header/Header';
import React from 'react';
import Scene from 'src/constants/scene';
import Toastr from 'src/components/Toastr';
import styled from 'styled-components';

/**
 * @description Base styled CSS
 */
const BaseStyled = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
`;
/**
 * @description View styled component
 */
const ViewStyled = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    margin: 1rem 1rem 1rem 1rem;
    overflow: auto;
    z-index: 1;
`;

const FooterContainer = styled.div`
    position: relative;
    bottom: 0;
    width: 100%;
    z-index: 0;
`;

const HeaderContainer = styled.div`
    position: relative;
    top: 0;
    width: 100%;
    z-index: 0;
`;

/**
 * @description Props for base component
 * @interface Props
 */
interface Props {
    /**
     * @description children of the base component
     * @type {React.ReactNode | undefined}
     */
    children?: React.ReactNode;
    /**
     * @description current scene
     * @type {Scene}
     */
    scene: Scene;
}
/**
 * @description Base component for HTML
 * @type {React.FC}
 */
const Base: React.FC<Props> = ({ scene, children }: Props) => {
    return (
        <BaseStyled>
            <HeaderContainer>
                <Header scene={scene} />
            </HeaderContainer>
            <ViewStyled>{children}</ViewStyled>
            <FooterContainer>
                <Footer />
            </FooterContainer>
            <Toastr />
        </BaseStyled>
    );
};

export default Base;
