import { BLACK, BORDER } from 'src/constants/colors';
import CycleLoader, {
    CycleLoaderContainer,
} from 'src/components/loader/CycleLoader';
import {
    MAX_WIDTH_PHONE_LANDSCAPE,
    MAX_WIDTH_PHONE_PORTRAIT,
} from 'src/constants/config';
import { Measurement, MeasurementData, Node } from '@nne-viz/common';
import { Method, useApi } from 'src/services/api';
import NodeGraphData, {
    measurementToNodeGraph,
} from 'src/models/NodeGraphData';
import { NorwayDayjs, norwaydayjsToUrlIso8601 } from 'src/utils/dates';
import React, { useEffect, useState } from 'react';

import CloseButton from 'src/components/buttons/CloseButton';
import NetworkList from 'src/components/network/NetworkList';
import NodeGraph from 'src/components/node/NodeGraph';
import TabButton from 'src/components/buttons/TabButton';
import { getMinutesRange } from 'src/utils/dates';
import styled from 'styled-components';
import { translateMeasurement } from 'src/utils/translate';

/**
 * @description header styled css
 */
const Header = styled.h2`
    display: flex;
    flex: 1;
`;
/**
 * @description header container styled css
 */
const HeaderContainer = styled.div`
    color: ${BLACK};
    border: solid;
    border-color: ${BORDER};
    border-width: 0 0 1px 0;
    display: flex;
    flex-direction: row;
    padding: 0 2rem 0 2rem;
`;
/**
 * @description subheader container styled css
 */
const SubHeaderContainer = styled.div`
    border: solid;
    border-color: ${BORDER};
    border-width: 0 0 1px 0;
    display: flex;
    flex-direction: row;
    padding: 1rem 2rem 0 2rem;
`;
/**
 * @description container styled css
 */
const Container = styled.div`
    display: grid;
    flex: 1;
    grid-template-columns: repeat(3, 1fr);
    padding: 1rem 2rem 1rem 2rem;

    @media (max-width: ${MAX_WIDTH_PHONE_PORTRAIT}px) {
        display: flex;
        flex-direction: column;
        padding: 0.5rem;
    }

    @media (max-width: ${MAX_WIDTH_PHONE_LANDSCAPE}px) and (min-width: ${MAX_WIDTH_PHONE_PORTRAIT +
        1}px) {
        display: flex;
        flex-direction: column;
        padding: 0.5rem;
    }
`;
/**
 * @description caption styled css
 */
const Caption = styled.p``;
/**
 * @description caption container styled css
 */
const CaptionContainer = styled.div`
    grid-row: 1 / 1;
    grid-column: 3 / 4;
    padding: 1rem 0rem 1rem 0rem;
`;
/**
 * @description background of the modal styled css
 */
const Background = styled.div`
    align-items: center;
    background-color: rgba(0, 0, 0, 0.25);
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1;
`;
/**
 * @description modal styled css
 */
const NodeModalStyled = styled.div`
    background: white;
    box-shadow: 0 0 2rem 0 ${BLACK};
    display: flex;
    flex-direction: column;
    height: 85%;
    width: 90%;

    @media (max-width: ${MAX_WIDTH_PHONE_PORTRAIT}px) {
        height: 95%;
        width: 95%;
        overflow-y: auto;
    }

    @media (max-width: ${MAX_WIDTH_PHONE_LANDSCAPE}px) and (min-width: ${MAX_WIDTH_PHONE_PORTRAIT +
        1}px) {
        height: 95%;
        width: 95%;
        overflow-y: auto;
    }
`;
/**
 * @description Props for the node modal component
 * @interface Props
 */
interface Props {
    /**
     * @function close
     * @description close the modal
     * @returns {void}
     */
    close: () => void;
    /**
     * @function getDatetime
     * @description give the datetime
     * @returns {NorwayDayjs}
     */
    getDatetime: () => NorwayDayjs;
    /**
     * @description node props from parents
     * @type {Node}
     */
    nodeId: number;
    /**
     * @description measurement to display by default
     * @type {Measurement | undefined}
     */
    measurement: Measurement | undefined;
}

const NodeModal: React.FC<Props> = ({
    nodeId,
    close,
    getDatetime,
    measurement,
}: Props) => {
    // Keys for the graphic, corresponding to the networks of the node
    const [graphicKeys, setGraphicKeys] = useState<string[]>([]);
    // Graphic state for displaying data
    const [graphicData, setGraphicData] = useState<NodeGraphData[]>([]);
    // Measurement for displaying data
    const [_measurement, _setMeasurement] = useState<Measurement>();

    const [{ data: measurements, isLoading: isMeasurementsLoading }] = useApi<
        Measurement[]
    >({
        method: Method.GET,
        isAuthorizationNeeded: false,
        initialData: [],
        initialEndpoint: 'node/measurement',
    });

    const [{ data: node, isLoading: isNodeLoading }] = useApi<Node | undefined>(
        {
            method: Method.GET,
            isAuthorizationNeeded: false,
            initialData: undefined,
            initialEndpoint: `node/${nodeId}`,
        }
    );

    const [
        { data: measurementData, isLoading: isMeasurementDataLoading },
        doFetchMeasurementData,
    ] = useApi<MeasurementData[]>({
        method: Method.GET,
        isAuthorizationNeeded: false,
        initialData: [],
    });

    useEffect(() => {
        if (measurement !== undefined && measurements.includes(measurement))
            _setMeasurement(measurement);
        else if (measurements.length > 0) _setMeasurement(measurements[0]);
    }, [measurements]);

    useEffect(() => {
        if (node)
            setGraphicKeys(node.networks.map((network) => network.provider));
    }, [node]);

    useEffect(() => {
        if (_measurement !== undefined)
            doFetchMeasurementData({
                endpoint: `node/measurement/${nodeId}/${_measurement}?from=${norwaydayjsToUrlIso8601(
                    getDatetime().subtract(getMinutesRange(_measurement), 'm')
                )}&to=${norwaydayjsToUrlIso8601(getDatetime())}`,
            });
    }, [_measurement]);

    useEffect(() => {
        setGraphicData(measurementToNodeGraph(measurementData));
    }, [measurementData]);
    /**
     * @function updateGraphicKeys
     * @description update the graphic keys according to the value
     * @param {string} value
     * @returns {void}
     */
    const updateGraphicKeys = (value: string): void => {
        setGraphicKeys(
            graphicKeys.includes(value)
                ? [...graphicKeys].filter((e) => e !== value)
                : [...graphicKeys, value]
        );
    };
    /**
     * @function renderCaption
     * @description render the caption according to what data is displayed
     * @param {Measurement} value the data to display in the graphic
     * @returns {JSX.Element}
     */
    const renderCaption = (value: Measurement): JSX.Element => {
        switch (value) {
            case Measurement.LATENCY:
                return (
                    <>
                        <Caption>
                            This plot shows the round-trip time (rtt) for each
                            connection. One 20 byte UDP packet is sent every
                            second to our server, and we record the time it
                            takes before it returns back to the measurement
                            node.
                        </Caption>
                        <Caption>
                            Note that the rtt depends heavily on the mode of the
                            connection. For example, a 3G connection will
                            generally have lower delays than a 2G connection,
                            and an HSPA+ connection will have lower delay than a
                            WCDMA connection. The mode of a connection again
                            depends on the traffic pattern. Hence, sending more
                            traffic can result in a lower rtt.
                        </Caption>
                    </>
                );
            case Measurement.PACKET_LOSS:
                return (
                    <Caption>
                        The plot shows the percentage of lost packets in each 5
                        minute interval. One packet is sent every second, and
                        the packet is considered lost if no reply is received
                        within 60 seconds.
                    </Caption>
                );
            default:
                return <></>;
        }
    };

    /**
     * @function renderModalContent
     * @description  render the content of the node modal according to loading and node state
     * @returns {JSX.Element}
     */
    const renderModalContent = (): JSX.Element => {
        // if is loading
        return isNodeLoading || isMeasurementsLoading ? (
            <>
                <HeaderContainer>
                    <Header>{nodeId}</Header>
                    <CloseButton onClick={close} />
                </HeaderContainer>
                <CycleLoaderContainer>
                    <CycleLoader />
                </CycleLoaderContainer>
            </>
        ) : // else if we have node, measurements and measurement
        node && measurements && _measurement ? (
            <>
                <HeaderContainer>
                    <Header>
                        {node.id} {node.address.name}
                    </Header>
                    <CloseButton onClick={close} />
                </HeaderContainer>
                <SubHeaderContainer>
                    {measurements.map((measurementIter) => (
                        <TabButton
                            text={translateMeasurement(measurementIter)}
                            key={measurementIter}
                            onClick={() => {
                                if (_measurement !== measurementIter)
                                    _setMeasurement(measurementIter);
                            }}
                            background={
                                _measurement === measurementIter
                                    ? 'transparent'
                                    : BORDER
                            }
                            borderWidth={
                                _measurement === measurementIter
                                    ? '2px 2px 0px 2px'
                                    : '0px 0px 0px 0px'
                            }
                            displayRefresh={_measurement === measurementIter}
                            onRefresh={() => {
                                doFetchMeasurementData({
                                    endpoint: `node/measurement/${nodeId}/${_measurement}?from=${norwaydayjsToUrlIso8601(
                                        getDatetime().subtract(
                                            getMinutesRange(_measurement),
                                            'm'
                                        )
                                    )}&to=${norwaydayjsToUrlIso8601(
                                        getDatetime()
                                    )}`,
                                });
                            }}
                        />
                    ))}
                </SubHeaderContainer>
                <Container>
                    <NodeGraph
                        datetime={getDatetime()}
                        graphicData={graphicData}
                        isGraphicLoading={isMeasurementDataLoading}
                        graphicKeys={graphicKeys}
                        value={_measurement}
                    />
                    <CaptionContainer>
                        <NetworkList
                            networks={node.networks}
                            updateGraphic={updateGraphicKeys}
                        />
                        {renderCaption(_measurement)}
                    </CaptionContainer>
                </Container>
            </>
        ) : (
            // otherwise if not requesting but no data
            <>
                <HeaderContainer>
                    <Header>{nodeId}</Header>
                    <CloseButton onClick={close} />
                </HeaderContainer>
            </>
        );
    };

    return (
        <Background>
            <NodeModalStyled>{renderModalContent()}</NodeModalStyled>
        </Background>
    );
};

export default NodeModal;
