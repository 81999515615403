import { Measurement, MeasurementStatistic } from '@nne-viz/common';
import { Method, useApi } from 'src/services/api';
import React, { useEffect } from 'react';

import Option from 'src/components/select/Option';
import Select from 'src/components/select/Select';
import styled from 'styled-components';
import { translateMeasurementStatistic } from 'src/utils/translate';

/**
 * @description select container style CSS
 */
const SelectContainer = styled.div`
    margin: 1rem 0rem 0rem 0rem;
`;
/**
 * @description props of the filter
 * @interface Props
 */
interface Props {
    text: string;
    measurement: Measurement;
    statistic: MeasurementStatistic | undefined;
    setStatistic: (statistic: MeasurementStatistic) => void;
}
/**
 * @description filter functional component
 * @type {React.FC}
 */
const StatisticSelect: React.FC<Props> = ({
    text,
    measurement,
    statistic,
    setStatistic,
}) => {
    // fetch statistic
    const [{ data: _statistics, isLoading }, doFetch] = useApi<
        MeasurementStatistic[]
    >({
        method: Method.GET,
        isAuthorizationNeeded: true,
        initialData: [],
        initialEndpoint: `network/measurement/statistic/${measurement}`,
    });
    // set by default when fetched
    useEffect(() => {
        if (_statistics.length > 0) setStatistic(_statistics[0]);
    }, [_statistics]);
    // refetch when changing measurement
    useEffect(() => {
        doFetch({
            endpoint: `network/measurement/statistic/${measurement}`,
        });
    }, [measurement]);

    return (
        <>
            <span>{text}</span>
            <SelectContainer>
                <Select
                    options={_statistics.map((xStatistic) => ({
                        value: xStatistic,
                        label: translateMeasurementStatistic(xStatistic),
                    }))}
                    isDisabled={isLoading}
                    value={{
                        value: statistic,
                        label: translateMeasurementStatistic(
                            statistic as MeasurementStatistic
                        ),
                    }}
                    isClearable={false}
                    onChange={(newValue) => {
                        const newStatistic: Option | undefined = newValue as
                            | Option
                            | undefined;
                        if (newStatistic)
                            setStatistic(
                                newStatistic.value as MeasurementStatistic
                            );
                    }}
                />
            </SelectContainer>
        </>
    );
};

export default StatisticSelect;
