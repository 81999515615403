import { BORDER, WHITE, provider } from 'src/constants/colors';
import { CircleProps, ResponsiveSwarmPlot } from '@nivo/swarmplot';
import {
    Measurement,
    MeasurementData,
    MeasurementStatistic,
    Network,
    Node,
} from '@nne-viz/common';
import { Method, useApi } from 'src/services/api';
import NetworkGraphSwarmData, {
    measurementToNetworkSwarmGraph,
} from 'src/models/NetworkGraphSwarmData';
import {
    NorwayDayjs,
    norwaydayjsToUrlIso8601,
    retrieveDaysInterval,
} from 'src/utils/dates';
import React, { useEffect, useState } from 'react';
import SquareLoader, {
    SquareLoaderContainer,
} from 'src/components/loader/SquareLoader';
import { translateMeasurement, translateProvider } from 'src/utils/translate';

import { measureUnit } from 'src/utils/math';
import styled from 'styled-components';

/**
 * @description container for the plot
 */
const PlotContainer = styled.div`
    height: 95%;
    width: 95%;
`;
/**
 * @description container for the tooltip when hover data in plot
 */
const TooltipContainer = styled.div`
    background: ${WHITE};
    border: solid;
    border-color: ${BORDER};
    border-radius: 7px;
    border-width: 1px;
    padding: 0.1rem 0.5rem;
`;
/**
 * @description props for network graph component
 * @type {Props}
 */

interface Props {
    /**
     * @description node to display data from
     * @type {Node[]}
     */
    displayedNodes: Node[];
    /**
     * @description is component loading
     * @type {boolean}
     */
    isLoading: boolean;
    /**
     * @description from date to display data from
     * @type {NorwayDayjs}
     */
    from: NorwayDayjs;
    /**
     * @description network from the data is displayed
     * @type {Network[]}
     */
    networks: Network[];
    /**
     * @description to date to display data to
     * @type {NorwayDayjs}
     */
    to: NorwayDayjs;
    /**
     * @description measurement to display
     * @type {Measurement}
     */
    measurement: Measurement;

    /**
     * @description statistic displayed
     * @type {MeasurementStatistic}
     */
    statistic: MeasurementStatistic;
}

/**
 * @description network item component for displaying network graph
 * @type {React.FC}
 */
const NetworkSwarmGraph: React.FC<Props> = ({
    networks,
    isLoading,
    from,
    to,
    displayedNodes,
    measurement,
    statistic,
}: Props) => {
    // data to display
    const [data, setData] = useState<NetworkGraphSwarmData[]>([]);
    // fetch data
    const [{ data: fetchData, isLoading: _isLoading }, doFetch] = useApi<
        MeasurementData[]
    >({
        method: Method.POST,
        isAuthorizationNeeded: true,
        initialData: [],
        initialEndpoint: `network/measurement/${measurement}/${statistic}?from=${norwaydayjsToUrlIso8601(
            from
        )}&to=${norwaydayjsToUrlIso8601(to)}`,
        initialBody: { networks_id: networks.map((network) => network.id) },
    });
    // useEffect run when component is first rendered
    useEffect(() => {
        doFetch({
            endpoint: `network/measurement/${measurement}/${statistic}?from=${norwaydayjsToUrlIso8601(
                from
            )}&to=${norwaydayjsToUrlIso8601(to)}`,
            body: { networks_id: networks.map((network) => network.id) },
        });
    }, [from, to, networks, measurement, statistic]);
    // useEffect to convert data when fetched
    useEffect(() => {
        setData(measurementToNetworkSwarmGraph(fetchData));
    }, [fetchData]);

    /**

     * @function tooltip
     * @description read https://nivo.rocks/swarmplot/ for tooltip description
     * create the div for displaying data when mouse is hover a data
     * @param {{data: NetworkGraphSwarmData}} node
     * @returns {JSX.Element}
     */

    const tooltip = (node: { data: NetworkGraphSwarmData }): JSX.Element => {
        // retrieve node data from the circle
        const networkData: NetworkGraphSwarmData = node.data;
        // format value for only 3 decimals after .
        const value = parseFloat(networkData.value.toString()).toFixed(3);
        return (
            <TooltipContainer>
                <p>
                    <strong>
                        Node {networkData.nodeId}{' '}
                        {translateProvider(networkData.provider)}
                    </strong>
                </p>
                <p>
                    {translateMeasurement(measurement as Measurement)}: {value}{' '}
                    {measureUnit(measurement as Measurement)}
                </p>
            </TooltipContainer>
        );
    };
    /**

     * @function CustomCircle
     * @description render custom circle
     * read https://nivo.rocks/swarmplot/ for more information
     * @param {CircleProps<NetworkGraphData>} props
     * @returns {JSX.Element}
     */

    const CustomCircle = (
        props: CircleProps<NetworkGraphSwarmData>
    ): JSX.Element => {
        const render = (
            <g
                transform={`translate(${props.node.x},${props.node.y})`}
                id={props.node.id}
            >
                <circle r={props.node.size / 2} strokeWidth={12} />
                <circle
                    r={props.node.size / 2}
                    fill={provider(props.node.data.provider)}
                    strokeWidth={6}
                    onMouseEnter={(event) => {
                        props.onMouseEnter?.(props.node, event);
                    }}
                    onMouseLeave={(event) => {
                        props.onMouseLeave?.(props.node, event);
                    }}
                />
            </g>
        );
        // #TODO fix two times displayedNodes checked
        return displayedNodes.find(
            (node) => props.node.data.nodeId === node.id
        ) !== undefined ? (
            render
        ) : (
            <></>
        );
    };
    // Data to display according to displayedNodes list
    const displayedData = data.filter(
        (data) =>
            displayedNodes.find((node) => node.id === data.nodeId) !== undefined
        // eslint-disable-next-line no-mixed-spaces-and-tabs
    );

    return _isLoading || isLoading ? (
        <SquareLoaderContainer>
            <SquareLoader />
        </SquareLoaderContainer>
    ) : (
        <PlotContainer>
            <ResponsiveSwarmPlot
                axisBottom={{ tickSize: 50, legend: 'Measurements\' days' }}
                axisLeft={{
                    tickSize: 50,
                }}
                axisRight={null}
                axisTop={null}
                circleComponent={CustomCircle}
                data={displayedData}
                groups={retrieveDaysInterval(from, to)}
                groupBy={'date'}
                margin={{ top: 82, right: 10, bottom: 65, left: 82 }}
                size={12}
                spacing={4}
                tooltip={tooltip}
                value={'value'}
                valueFormat={'.3f'}
            ></ResponsiveSwarmPlot>
        </PlotContainer>
    );
};

export default NetworkSwarmGraph;
