import {
    FONT_SIZE_DESKTOP,
    FONT_SIZE_PHONE,
    MAX_WIDTH_PHONE_LANDSCAPE,
    MAX_WIDTH_PHONE_PORTRAIT,
} from 'src/constants/config';
import { MAIN_THEME, MEDIUM_GRAY, WHITE } from 'src/constants/colors';

import React from 'react';
import styled from 'styled-components';

interface HeaderButtonStyledProps {
    isSelected: boolean;
}
/**
 * @description header button style CSS
 */
const HeaderButtonStyled = styled.button<HeaderButtonStyledProps>`
    align-items: center;
    background: ${(props) => (props.isSelected ? WHITE : 'transparent')};
    border: solid;
    border-color: ${WHITE};
    border-radius: 5px 5px 0 0;
    border-width: 1.5px 1.5px 0 1.5px;
    color: ${(props) => (props.isSelected ? MAIN_THEME : WHITE)};
    display: flex;
    font-size: ${FONT_SIZE_DESKTOP}em;
    font-weight: 500;
    margin: ${(props) => (props.isSelected ? '0.7' : '1.5')}rem 0.5rem 0 0.5rem;
    padding: 0rem 4rem 0rem 4rem;

    &:hover {
        background: ${(props) => (props.isSelected ? WHITE : MEDIUM_GRAY)};
        border-color: ${(props) => (props.isSelected ? WHITE : MEDIUM_GRAY)};
        color: ${MAIN_THEME};
        margin: 0.7rem 0.5rem 0 0.5rem;
    }

    @media (max-width: ${MAX_WIDTH_PHONE_PORTRAIT}px) {
        padding: 0rem 0.5rem 0rem 0.5rem;
        font-size: ${FONT_SIZE_PHONE}rem;
    }

    @media (max-width: ${MAX_WIDTH_PHONE_LANDSCAPE}px) and (min-width: ${MAX_WIDTH_PHONE_PORTRAIT +
        1}px) {
        padding: 0rem 1.5rem 0rem 1.5rem;
        font-size: ${FONT_SIZE_PHONE}rem;
    }
`;
/**
 * @description props for the header button component
 * @interface Props
 */
interface Props {
    /**
     * @description text to display in the button
     * @type {string}
     */
    text: string;
    /**
     * @description function to run on click the button
     */
    onClick: () => void;
    /**
     * @description is button selected
     * @type {boolean}
     */
    isSelected: boolean;
}
/**
 * @description header button functional component
 * @type {React.FC}
 */
const HeaderButton: React.FC<Props> = ({ text, onClick, isSelected }) => {
    return (
        <HeaderButtonStyled isSelected={isSelected} onClick={() => onClick()}>
            <span>{text}</span>
        </HeaderButtonStyled>
    );
};

export default HeaderButton;
