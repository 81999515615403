import React, { useState } from 'react';

import { BORDER } from 'src/constants/colors';
import { Network } from '@nne-viz/common';
import NetworkItem from 'src/components/network/NetworkItem';
import TableBody from 'src/components/table/TableBody';
import TableFooter from 'src/components/table/TableFooter';
import TableHeader from 'src/components/table/TableHeader';
import styled from 'styled-components';

/**
 * @description list the keys of network to display
 * @enum {string}
 */
enum NetworkKeys {
    ID = 'id',
    Provider = 'provider',
    Strength = 'signalStrength',
    Status = 'status',
}

/**
 * @description network list style CSS
 */
const NetworkListStyled = styled.table`
    border: solid;
    border-color: ${BORDER};
    border-radius: 5px;
    border-width: 1px;
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow-y: scroll;
`;
/**
 * @description props of the network list
 * @interface Props
 */
interface Props {
    /**
     * @description the networks to display in the list
     * @type {Network[]}
     */
    networks: Network[];
    /**
     * @function updateGraphic
     * @description update the graphic
     * @param {string} value
     * @returns {void}
     */
    updateGraphic: (value: string) => void;
}
/**
 * @description network list functional component to display a list of node
 * @type {React.FC}
 */
const NetworkList: React.FC<Props> = ({ networks, updateGraphic }: Props) => {
    // Ascending sort for the list
    const [ascending, setAscending] = useState<boolean>(false);
    // List display of nodes
    const [list, setList] = useState<Network[]>(networks);
    /**
     * @function sort
     * @description sort the list according to the header value
     * @param {string} header
     * @returns {void}
     */
    const sort = (header: string): void => {
        // IMPORTANT: Do array spreading for creating a copy
        const sortedList = [...list];
        const key = NetworkKeys[header as keyof typeof NetworkKeys];
        setAscending(!ascending);
        sortedList.sort((a, b) =>
            ascending
                ? a[key as keyof Network] > b[key as keyof Network]
                    ? -1
                    : 1
                : a[key as keyof Network] < b[key as keyof Network]
                ? -1
                : 1
        );
        setList(sortedList);
    };
    // convert network to item to display
    const toNetworkItems = list.map((network, index) => (
        <NetworkItem
            key={network.id}
            network={network}
            isLastIndex={index == list.length - 1}
            onClick={updateGraphic}
        />
    ));
    return (
        <NetworkListStyled>
            <TableHeader
                values={['Color', ...Object.keys(NetworkKeys)]}
                sort={sort}
                isSelectable={false}
            />
            <TableBody>{toNetworkItems}</TableBody>
            <TableFooter></TableFooter>
        </NetworkListStyled>
    );
};

export default NetworkList;
